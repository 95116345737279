import { Component, input } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  imports: [],
  templateUrl: './empty-list.component.html',
  styleUrl: './empty-list.component.scss',
})
export class EmptyListComponent {
  description = input<string>('Список пуст');
}
